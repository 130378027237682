import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
const Web3 = require('web3');
const { ethers } = require('ethers');
declare let require: any;
declare let window: any;
const tokenAbi = require('../contract/abis.json');
import Swal from 'sweetalert2';
import WalletConnectProvider from '@walletconnect/web3-provider';
@Injectable({
  providedIn: 'root',
})
export class ConnectService {
  public account: any = null;
  public web3: any;
  private enable: any;
  private contract: any;
  private provider: any;
  public saleStarted: boolean = false;
  public currentPrice: any = 0.001;
  private signer: any;
  private balance: any;

  constructor() {
    var self = this;
    if (typeof window.web3 !== 'undefined') {
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload();
      });
      window.ethereum.on('networkChanged', function (networkId) {
        window.location.reload();
      });
      window.ethereum.on('connect', function (networkId) {
        console.log('connect');

        self.connectWallet();
      });
    }
  }

  public async connectWallet() {
    if (window.ethereum === undefined) {
      if (this.isIOS()) {
        let providerEth = new WalletConnectProvider({
          infuraId: '9aa3d95b3bc440fa88ea12eaa4456161',
        });

        await providerEth.enable();
        this.provider = new ethers.providers.Web3Provider(providerEth);

        this.provider.off('disconnect');
        this.provider.on('disconnect', () => {});

        if (this.provider !== undefined) {
          console.log('this.provider', this.provider);

          this.signer = this.provider.getSigner();
          /* @ts-ignore */
          const signerAddress: any = await this.signer.getAddress();

          this.account = signerAddress;

          console.log(signerAddress);
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'No Metamask Detected. Please install Metamask.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      }
    } else {
      if (typeof window.web3 !== 'undefined') {
        this.web3 = window.web3.currentProvider;
      }

      window.web3 = new Web3(window.ethereum);
      await window.web3.eth.getChainId().then(async (data) => {
        console.log(data);

        if (data === 1) {
          console.log('transfer.service :: constructor :: this.web3');

          let enable = await window.ethereum.enable();
          console.log('enable', enable);

          if (enable && enable.length > 0) {
            this.account = enable[0];
          }
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Please select Ethereum Network in metamask.',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        }
      });
    }
  }

  public async convertJSONtoHEX(value) {
    return window.web3.utils.toHex(value);
  }

  public async connectContract() {
    if (this.isIOS()) {
      this.contract = await new ethers.Contract(
        environment.CONTRACT_ADDRESS,
        tokenAbi,
        this.signer
      );
    } else {
      this.contract = await new window.web3.eth.Contract(
        tokenAbi,
        environment.CONTRACT_ADDRESS
      );
    }
  }

  public async getTotalSupply() {
    await this.connectContract();
    if (this.isIOS()) {
      var response = await this.contract.totalSupply();
      var startTime: any = await this.contract._startDate();
      this.saleStarted = await this.contract.sale_started();
      var currentTime = await this.contract.getnow();
      var diff = currentTime - startTime;

      if (this.saleStarted) {
        if (diff >= 4320) {
          this.currentPrice = 0.07;
        } else {
          this.currentPrice = 0.06;
        }
      } else {
        this.currentPrice = 0.07;
      }
      return response;
    } else {
      var response = await this.contract.methods.totalSupply().call();
      var startTime = await this.contract.methods._startDate.call().call();
      this.saleStarted = await this.contract.methods.sale_started.call().call();
      var currentTime = await this.contract.methods.getnow.call().call();
      var diff = currentTime - startTime;
      if (this.saleStarted) {
        if (diff >= 259200) {
          this.currentPrice = 0.07;
        } else {
          this.currentPrice = 0.06;
        }
      } else {
       this.currentPrice = 0.07;
      }
      return response;
    }
  }

  public async mint(quantity, price) {
    console.log(price * Math.pow(10, 18));
    //onsole.log(ethers.BigNumber.from(price.toString()).pow(18));

    price = quantity * 70000000000000000;

    await this.connectContract();
    if (this.isIOS()) {
       var response = await this.contract
         .mint(quantity, {
           value: price,
           gasLimit: 550000,
           gasPrice: 150000000000,
         })
         .then((success) => {
           console.log('success==========', success);
           return success;
         })
         .catch((error) => {
           console.log('error==========', error);
           return error;
         });

      return response;
    } else {
      window.web3.eth.Contract.handleRevert = true;
      var response = await this.contract.methods
        .mint(quantity)
        .send({
          from: this.account,
          value: price
        })
        .once('receipt', async (receipt) => {
          console.log('receipt==========', receipt);
        })
        .catch((error) => {
          console.log('error==========', error);
        });

      return response;
    }
  }

  disconnect() {
    this.account = '';
  }

  public isIOS() {
    if (
      navigator.userAgent.match(/iPhone|iPad|iPod/i) &&
      !navigator.userAgent.match(/CriOs/i) &&
      navigator.userAgent.match(/Safari/i)
    ) {
      console.log(true);
      return true;
    } else {
      console.log(true);
      return false;
    }
  }
}
